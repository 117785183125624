import React, {useEffect, useState} from 'react';
import './Checkout.scss';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../components/Button/Button';
import {PaperAirplaneIcon, XMarkIcon} from '@heroicons/react/24/solid';
import CartItem from './CartItem/CartItem';
import {Link} from 'react-router-dom';
import {clearItems} from '../../redux/cart.reducer';
import {ReactComponent as EmptyCart} from '../../assets/svgs/empty_cart.svg';
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import Page from '../../components/common/Page/Page';
import {sendMessage} from '../../firebase/message.store';
import {showNotification} from '../../redux/notification.reducer';
import {addUser, getUser} from '../../firebase/users.store';

const Checkout = () => {

  const items = useSelector(state => state.cart.items);
  const dispatch = useDispatch();

  const [cartItems, setCartItems] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  useEffect(() => {
    dispatch(addCrumbs([{label: 'Checkout', link: '/checkout'}]));
  }, []);

  useEffect(() => {
    let array = [];
    Object.keys(items)
        .forEach(value => {
          array.push(items[value]);
        });
    setCartItems(array);
  }, [items]);

  const isButtonDisabled = () => getCartItemsCount() <= 0 || hasRequiredContactInfo(contactInfo);

  const setContactData = (attribute, value) => setContactInfo(prevState => ({...prevState, [attribute]: value}));

  const hasRequiredContactInfo = (data) => data.name?.length <= 0 || data.email?.length <= 0;

  const getCartItemsCount = () => cartItems.length;

  const submitInquiry = async () => {
    try {
      await sendMessage({
        to: {
          email: contactInfo.email || '',
          name: contactInfo.name || '',
          phone: contactInfo.phone || ''
        },
        type: 'product',
        data: {
          products: sanitizeProductsForEmailTemplate(cartItems.map(value => value.item)),
        }
      });

      const userData = await getUser(contactInfo.email);
      if (!userData.email) {
        await addUser({
          id: contactInfo.email,
          name: contactInfo.name,
          email: contactInfo.email,
          phone: contactInfo.phone
        });
      }

      dispatch(showNotification({
        message: '🎉 Thank you for inquiring about our products, we will get back to you soon!',
        title: 'Message Received! We\'re on It!',
        type: 'success',
        duration: 5_000
      }));

      dispatch(clearItems());

    } catch (e) {
      console.error(e);
    }
  };

  const sanitizeProductsForEmailTemplate = (products) => {
    return products.map(product => {
      const {productInformation, index} = getProductInformation(product);
      return {
        name: product.name || '',
        id: product.id.split('__')[0] || '',
        model: productInformation.meta.model || '-',
        brand: productInformation.meta.brand || '-',
        capacity: productInformation.capacity || '-',
        platformSize: productInformation.platformSize || '-',
        price: productInformation.price || 0,
        variantIndex: index,
        categoryId: product.categoryId || '',
        thumbnail: product.thumbnail,
      };
    });
  };

  const getProductInformation = (product) => {
    const variantIndex = product.id.split('__')[1];
    if (variantIndex >= 0) {
      return {productInformation: product.variants[variantIndex], index: variantIndex};
    }
    return {productInformation: product.variants[0], index: 0};
  };

  return (
      <>
        <Page title={'Checkout'} className={'checkout-page'}>
          <div className="checkout-page_content">
            <section className="item-section">
              <div className="action-bar">
                <h2 className="title">
                  {
                    getCartItemsCount() === 1 ? `1 Item` : `${getCartItemsCount()} Items`
                  }
                </h2>
                <div className="spacer"></div>
                {
                    cartItems?.length > 0 &&
                    <Button onClick={() => {
                      dispatch(clearItems());
                    }}>
                      Clear Cart
                      <XMarkIcon className={'heroicon-20'}/>
                    </Button>
                }
              </div>
              <br/>
              {
                cartItems.length ? <ul className="cart-items">
                      {
                        cartItems.map((value, index) =>
                            <CartItem key={`${value.item}-${index}`} product={value.item} qty={value.qty}/>
                        )
                      }
                    </ul> :
                    <>
                      <div className="empty">
                        {/*<span className={'empty-message'}>*/}
                        {/*  No Items in the Cart*/}
                        {/*  </span>*/}
                        <EmptyCart style={{height: '200px'}}/>
                        <Link to="/products">
                          <Button className={'solid primary'}>
                            Browse Our Products
                            <ArrowTopRightOnSquareIcon className={'heroicon-20'}/>
                          </Button>
                        </Link>
                      </div>
                    </>
              }

            </section>
            <section className="contact-section">
              <div className="contact-forms">
                <form>
                  <div className="half">
                    <fieldset>
                      <label htmlFor="name">Name<sup>*</sup></label>
                      <input type="text" id={'name'} placeholder={'Enter your name'} autoComplete={'given-name'}
                             onInput={(e) => setContactData('name', e.currentTarget.value)}
                             value={contactInfo.name} required/>
                    </fieldset>
                  </div>
                  <div className="half">
                    <fieldset>
                      <label htmlFor="email">Email<sup>*</sup></label>
                      <input type="email" id={'email'} placeholder={'Enter your email'} autoComplete={'email'}
                             onInput={(e) => setContactData('email', e.currentTarget.value)}
                             value={contactInfo.email} required/>
                    </fieldset>
                  </div>
                  <div className="half">
                    <fieldset>
                      <label htmlFor="phone">Phone #</label>
                      <input type="tel" id={'tel'} placeholder={'Enter your phone number'} autoComplete={'tel'}
                             onInput={(e) => setContactData('phone', e.currentTarget.value)}
                             value={contactInfo.phone}/>
                    </fieldset>
                  </div>
                  {/*<div className="full">*/}
                  {/*  <fieldset>*/}
                  {/*    <label htmlFor="message">Message</label>*/}
                  {/*    <textarea id={'message'} placeholder={'Please leave us a short message...'}*/}
                  {/*              onInput={(e) => setContactData('message', e.currentTarget.value)}*/}
                  {/*              value={contactInfo.message}/>*/}
                  {/*  </fieldset>*/}
                  {/*</div>*/}
                  <div className="action-bar full">
                    <div className="spacer"></div>
                    <Button className={'primary solid pill'}
                            disabled={isButtonDisabled()}
                            onClick={submitInquiry} type={'button'}>
                      Submit Inquiry
                      <PaperAirplaneIcon className={'heroicon-24'}/>
                    </Button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Page>
      </>
  );
};

export default Checkout;
