import React, {useEffect, useState} from 'react';
import './ProductDetails.scss';
import Button from '../Button/Button';
import {ArrowLeftIcon, MinusIcon, PlusIcon} from '@heroicons/react/20/solid';
import ImageGallery from '../Image-Gallery/ImageGallery';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab/Tab';
import DocumentList from '../Document-List/DocumentList';
import {useDispatch, useSelector} from 'react-redux';
import {addItem} from '../../redux/cart.reducer';
import {ShoppingCartIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';
import Loading from '../common/Loading/Loading';
import Page from '../common/Page/Page';
import ProductModelItem from '../Product-Model-Item/product-model-item';
import {showNotification} from '../../redux/notification.reducer';
import {formatPrice} from '../../utils/common';


const ProductDetails = (props) => {

  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [quantities, setQuantities] = useState([]);
  const cartItems = useSelector(state => state.cart.items);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setQuantities(props.product.variants.map(value => 1));
    setProduct(props.product);
    setLoading(false);
  }, []);

  useEffect(() => {
  }, [cartItems]);

  const hasProductInformationOrAssets = () => (product.information?.images?.length || product.information?.html?.length) || (product.assets?.manuals?.length || product.assets?.priceSheets?.length);

  const addItemToCart = (index) => {
    const {updatedAt, createdAt, ...productToAdd} = product;
    dispatch(addItem({
      qty: quantities[index],
      item: {...productToAdd, id: product.variants[index].id}
    }));

    const newQuantities = [...quantities];
    newQuantities[index] = 1;
    setQuantities(newQuantities);

    //   Show the notification
    dispatch(showNotification({
      message: 'Items have been added to your cart!',
      title: 'Items Added!',
      type: 'success',
      duration: 5_000
    }));
  };

  const updateProductQuantity = (index, change) => {
    const currentQuantity = quantities[index];
    setProductQuantity(index, currentQuantity + change);
  };

  const setProductQuantity = (index, value) => {
    const newQuantities = [...quantities];
    newQuantities[index] = value;
    setQuantities(newQuantities);
  };


  return (
      <>
        <Page>
          {
            loading ? <>
              <Loading title={'Loading Product...'} subTitle={'Please wait while we load your product.'}/>
            </> : <section className="product-details-page">
              <header className="action-bar page-header vertical">
                <Link to={'..'} relative={'path'}>
                  <Button isLink={true}>
                    <ArrowLeftIcon className={'heroicon-20'}/> View All Products
                  </Button>
                </Link>
              </header>
              <section className="page-details">
                <article className="product-main">
                  <ImageGallery images={product.assets.images}/>
                  <div className="product-data">
                    <div className="action-bar vertical">
                      <h2 className="page-title">{product.name}</h2>
                      <h4 className="page-subtitle">{product.categoryName}</h4>
                    </div>
                    <div className="product-description"
                         dangerouslySetInnerHTML={{__html: product.shortDescription}}>
                    </div>
                  </div>
                </article>
                <table className="product-table more-than-mid-laptop">
                  <thead>
                  <tr>
                    <td>Model</td>
                    <td>Weight Capacity</td>
                    <td>Platform Size</td>
                    <td>Price</td>
                    <td className={'small'}>Quantity</td>
                    <td className={'small'}></td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    product.variants.map((variant, index) => (
                        <tr key={`${variant.id}-${index}`}>
                          <td>{variant.meta.model || '-'}</td>
                          <td>{variant.capacity || '-'}</td>
                          <td>{variant.platformSize || '-'}</td>
                          <td>{variant.price ? formatPrice(variant.price) : '-'}</td>
                          <td>
                            <div className={'action-bar controls'}>
                              <button className={'control minus'} onClick={() => {
                                updateProductQuantity(index, -1);
                              }}>
                                <MinusIcon className={'heroicon-20'}/>
                              </button>
                              <input type="number" min={1} max={100} className={'input-value'} value={quantities[index]}
                                     onChange={(e) => {
                                       setProductQuantity(index, +e.target.value);
                                     }}/>
                              <button className={'control add'} onClick={() => {
                                updateProductQuantity(index, 1);
                              }}>
                                <PlusIcon className={'heroicon-20'}/>
                              </button>
                            </div>
                          </td>
                          <td>
                            <Button className={'primary solid add-to-cart-button'} onClick={() => {
                              addItemToCart(index);
                            }}>
                              Add to Cart
                              <ShoppingCartIcon className={'heroicon-20'}/>
                            </Button>
                          </td>
                        </tr>
                    ))
                  }
                  </tbody>
                </table>
                <section className="model-section less-than-mid-laptop">
                  <div className="horizontal-divider"></div>
                  <h2 className="title">Models</h2>
                  <ul className="models-list">
                    {
                      product.variants.map((variant, index) => (
                          <li key={`${variant.id}-${index}`}>
                            <ProductModelItem
                                            variant={variant}
                                            updateProductQuantity={updateProductQuantity}
                                            setProductQuantity={setProductQuantity}
                                            addItemToCart={addItemToCart}
                                            quantity={quantities[index]}
                                            index={index}/>
                          </li>
                      ))
                    }


                  </ul>
                </section>
                {
                    hasProductInformationOrAssets() &&
                    <>
                      <div className="horizontal-divider"></div>
                      <div className="product-meta">
                        <Tabs>
                          {
                              (product.information?.images?.length || product.information?.html?.length) &&
                              <Tab label={'Product Details'}>
                                <div className={'product-details'}>
                                  {
                                    product.information.images
                                        .map((value, index) => <img src={value.url} alt={value.name}
                                                                    key={`${value.name}-index`}
                                                                    className={'product-details__image'}/>)
                                  }
                                </div>
                              </Tab>
                          }
                          {
                              (product.assets?.manuals?.length || product.assets?.priceSheets?.length) &&
                              <Tab label={'Documents'}>
                                <DocumentList priceSheets={product.assets.priceSheets}
                                              manuals={product.assets.manuals}/>
                              </Tab>
                          }
                        </Tabs>
                      </div>
                    </>
                }

              </section>
            </section>
          }
        </Page>
      </>
  );
};

export default ProductDetails;
