import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import Button from '../Button/Button';
import {NavLink} from 'react-router-dom';
import {PhoneIcon} from '@heroicons/react/20/solid';
import CompanyLogo from './Final-Logo.png';
import {Bars3BottomRightIcon, EnvelopeIcon, ShoppingCartIcon, XMarkIcon} from '@heroicons/react/24/solid';
import {LINKS, TOP_LINKS} from '../../constants/links';
import {useDispatch, useSelector} from 'react-redux';
import {setCartItems} from '../../redux/cart.reducer';

const Navbar = (props) => {

  const items = useSelector(state => state.cart.items);

  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    if (Object.keys(items)?.length > 0) {
      setNotification(true);

      setTimeout(() => {
        setNotification(false);
      }, 1000);
    }
  }, [items]);

  useEffect(() => {
    const localCartItems = JSON.parse(localStorage.getItem('cart') || '{}');
    if (localCartItems) {
      dispatch(setCartItems(localCartItems));
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const cartCount = () => Object.keys(items)?.reduce((sum, key) => items[key].qty + sum, 0) || 0;

  return (
      <>
        <nav>
          <div className="row top laptop">
            <div className="action-bar content">
              <a href={'tel:+1-437-460-9068'} className={'nav-cta laptop'}>
                <Button className={'light outline small'}>
                  <PhoneIcon className={'heroicon-16'}/>
                  <span className="phone-text">+1.437.460.9068</span>
                </Button>
              </a>
              <a
                  href="mailto:hiren@weightechsolutions.ca" target={'_blank'} className={'nav-cta laptop'}
                  rel={'noreferrer'}
              >
                <Button className={'light solid small'}>
                  <EnvelopeIcon className={'heroicon-16'}/>
                  <span className="email-text">hiren@weightechsolutions.ca</span>
                </Button>
              </a>

              <span className="spacer"></span>
              <ul className="top-links">
                {
                  TOP_LINKS.map(({to, end, label}, index) =>
                      <NavLink
                          className={`link`} to={to} end={end}
                          key={`${index}-${to}`} onClick={() => {
                        setMenuOpen(false);
                      }}
                      >
                        {label}

                      </NavLink>
                  )
                }
              </ul>
            </div>
          </div>

          <div className="navbar">
            <div className="row">
              <div className="logo">
                <NavLink to={'/'} end style={{cursor: 'pointer'}}>
                  <img src={CompanyLogo} alt="Company Logo" className="company-logo"/>
                </NavLink>
              </div>
              <div className="spacer"></div>

              <div className={`links ${menuOpen ? 'open' : ''}`}>
                {
                  LINKS.map(({to, end, label}, index) =>
                      <NavLink
                          className={({isActive}) => `${isActive ? 'active' : ''} link`} to={to} end={end}
                          key={`${index}-${to}`} onClick={() => {
                        setMenuOpen(false);
                      }}
                      >
                        {label}

                      </NavLink>
                  )
                }
                {/*<div className="search-bar">*/}
                {/*  <input type="text" name="search" id="search"*/}
                {/*         placeholder={'Search for a product'}/>*/}
                {/*  <MagnifyingGlassIcon className={'heroicon-24'}/>*/}
                {/*</div>*/}
                {/*<Link to="contact">*/}
                {/*  <Button*/}
                {/*      className={'primary solid cta animate__animated animate__pulse animate__repeat-3'}>Inquire!</Button>*/}
                {/*</Link>*/}
                <NavLink to="checkout" end={true}>
                  <Button className={`solid pill accent animate__animated ${notification && 'animate__heartBeat'}`}>
                    <span className={`less-than-laptop`}>Shopping Cart</span>
                    <ShoppingCartIcon className={'heroicon-24'}/>
                    <span>{cartCount()}</span>
                  </Button>
                </NavLink>
              </div>
              <div className="mobile-menu-icon">
                <Button className={'primary icon-only'} onClick={toggleMenu}>
                  {
                    menuOpen ?
                        <XMarkIcon className={'heroicon-24'}/>
                        :
                        <Bars3BottomRightIcon className={'heroicon-24'}/>
                  }
                </Button>
              </div>
            </div>

            {/*<div className="row">*/}
            {/*  <ul className={`links ${menuOpen ? 'open' : ''}`}>*/}
            {/*    {*/}
            {/*      LINKS.map(({to, end, label}, index) =>*/}
            {/*          <NavLink*/}
            {/*              className={({isActive}) => `${isActive ? 'active' : ''} link`} to={to} end={end}*/}
            {/*              key={`${index}-${to}`} onClick={() => {*/}
            {/*            setMenuOpen(false);*/}
            {/*          }}*/}
            {/*          >*/}
            {/*            {label}*/}

            {/*          </NavLink>*/}
            {/*      )*/}
            {/*    }*/}

            {/*    <span className="spacer"></span>*/}
            {/*    <a href={'tel:+1-437-460-9068'} className={'nav-cta laptop'}>*/}
            {/*      <Button className={'primary outline small'}>*/}
            {/*        <PhoneIcon className={'heroicon-16'}/>*/}
            {/*        <span className="phone-text">+1.437.460.9068</span>*/}
            {/*      </Button>*/}
            {/*    </a>*/}
            {/*    <a*/}
            {/*        href="mailto:hiren@weightechsolutions.ca" target={'_blank'} className={'nav-cta laptop'}*/}
            {/*        rel={'noreferrer'}*/}
            {/*    >*/}
            {/*      <Button className={'primary solid small'}>*/}
            {/*        <EnvelopeIcon className={'heroicon-16'}/>*/}
            {/*        <span className="email-text">hiren@weightechsolutions.ca</span>*/}
            {/*      </Button>*/}
            {/*    </a>*/}

            {/*    <a href={'tel:+1-437-460-9068'} className={'nav-cta mobile'}>*/}
            {/*      <Button className={'primary outline'}>*/}
            {/*        <PhoneIcon className={'heroicon-20'}/>*/}
            {/*        <span className="phone-text">+1.437.460.9068</span>*/}
            {/*      </Button>*/}
            {/*    </a>*/}
            {/*    <a*/}
            {/*        href="mailto:hiren@weightechsolutions.ca" target={'_blank'} className={'nav-cta mobile'}*/}
            {/*        rel={'noreferrer'}*/}
            {/*    >*/}
            {/*      <Button className={'primary solid'}>*/}
            {/*        <EnvelopeIcon className={'heroicon-20'}/>*/}
            {/*        <span className="email-text">hiren@weightechsolutions.ca</span>*/}
            {/*      </Button>*/}
            {/*    </a>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>
        </nav>
      </>
  );
};

export default Navbar;
