import {MinusIcon, PlusIcon} from '@heroicons/react/20/solid';
import Button from '../Button/Button';
import {ShoppingCartIcon} from '@heroicons/react/24/solid';
import React from 'react';
import './product-model-item.scss';
import {formatPrice} from '../../utils/common';

const ProductModelItem = ({showButtonControls = true, showAddToCartButton = true, ...props}) =>
    <div
        className="model-card">
      <div className="model-card_header">
        <div className="model-card_title">
          {props.variant.meta.model || '-'}
        </div>
        <div className="model-card_price">
          {props.variant.price ? formatPrice(props.variant.price) : '-'}
        </div>
      </div>
      <div className="model-card_content">
        {
            props.variant.capacity &&
            <div className="model-data_line">
              <div className="model-data_label">Weight Capacity:</div>
              <div className="model-data_value">{props.variant.capacity || '-'}</div>
            </div>
        }
        {
            props.variant.platformSize &&
            <div className="model-data_line">
              <div className="model-data_label">Platform Size:</div>
              <div className="model-data_value">{props.variant.platformSize || '-'}</div>
            </div>
        }
      </div>
      <div className="model-card_footer">
        <div className={'action-bar controls'}>
          {
              showButtonControls &&
              <button className={'control minus'} onClick={() => {
                props.updateProductQuantity(props.index, -1);
              }} disabled={props.quantity <= 1}>
                <MinusIcon className={'heroicon-20'}/>
              </button>
          }
          <input type="number" min={1} max={100} className={'input-value'} value={props.quantity}
                 onChange={(e) => {
                   props.setProductQuantity(props.index, +e.target.value);
                 }}/>
          {
              showButtonControls &&
              <button className={'control add'} onClick={() => {
                props.updateProductQuantity(props.index, 1);
              }} disabled={props.quantity >= 100}>
                <PlusIcon className={'heroicon-20'}/>
              </button>
          }
        </div>
        {
            showAddToCartButton &&
            <Button className={'primary solid add-to-cart-button'} onClick={() => {
              props.addItemToCart(props.index);
            }}>
              Add to Cart
              <ShoppingCartIcon className={'heroicon-20'}/>
            </Button>
        }
      </div>
    </div>;

export default ProductModelItem;